import check from "check-types";

export default {
	methods: {

		// Some Validation Checks 
		checkAddress(){			
			if(!this.checkPhoneNumber(this.phone)) {
		    	this.$toasted.error("Please Enter Your 10 Digit Phone Number");        
			}
			else if(!this.checkStreetAddress(this.streetAddress)) {
		    	this.$toasted.error("Please Enter Your street address correctly");        
			}			
			else if(!this.checkPincode(this.pincode)) {
		    	this.$toasted.error("Please Enter Your Pincode correctly");        
			}						
			else if(this.selectedAddress.length == 0) {
		    	this.$toasted.error("Please Select A Locality From The List");
			} 			
			else if (this.name.length == 0) {
		    	this.$toasted.error("Please Enter Your Name");                
			} 			
			else {
		    	return true
			}
			return false
		},
		checkPhoneNumber(phone) {
			if(check.not.number(phone) || check.not.inRange(phone, 6000000000, 9999999999)) { 
				return false 
			} 
			return true
		},
		checkStreetAddress(streetAddress) {
			return !!streetAddress.length
		},
		checkPincode(pincode) {								
			if(check.not.number(pincode) || pincode.toString().length !== 6) {
				return false
			}
			return true
		}
	},
	computed:{
		getUserCity(){
			return localStorage.getItem('city')
		},
		getUserLocation(){
			return localStorage.getItem('location')
		},

	}
}